import firebase, { db } from "../../firebase"
import "instantsearch.css/themes/algolia.css"
import "instantsearch.css/themes/reset.css"
import Container from "../../lib/components/Container"
import React, { useEffect, useState } from "react"
import { List, ListItem, ListItemText, Card,
  CardHeader, Grid, Divider, Collapse } from "@material-ui/core"
import { connectHits, Panel, InstantSearch,
  SearchBox, connectStateResults } from "react-instantsearch-dom"
import { Link } from "react-router-dom"
import useStyles from "./styles"
// icons
import EditIcon from "@material-ui/icons/Edit"
import ClaimIcon from "@material-ui/icons/Assignment"
import CasinoIcon from "@material-ui/icons/Casino"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import PhoneIcon from "@material-ui/icons/Phone"
import EmailIcon from "@material-ui/icons/Email"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar"
import FingerprintIcon from "@material-ui/icons/Fingerprint"
import PermIdentityIcon from "@material-ui/icons/PermIdentity"
import BusinessIcon from "@material-ui/icons/Business"
import ListIcon from "@material-ui/icons/List"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"


const customSearchClient = {
  search: async (requests: any[]) => {
    console.info("Trigger search")

    try {
      // eslint-disable-next-line import/no-named-as-default-member
      const auth = firebase.auth()
      // @ts-expect-error
      const token = await auth.currentUser.getIdToken()

      const res = await fetch(
        `${process.env.REACT_APP_ALGOLIA_PROXY_URL}/search`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ requests }),
        },
      )

      const jsonRes = await res.json()

      return jsonRes
    }
    catch (error) {
      console.error(error)
    }
  },
}

type CustomFieldValues = Record<string, (any)>

interface SearchHit {
  id: string
  purchaseContractFirstName: string
  purchaseContractLastName: string
  companyName: string
  userEmail: string
  phoneNumber: string
  address: string
  city: string
  postcode: string
  carBrand: string
  carModel: string
  carYear: number
  vinNumber: string
  objectID: string
  type: string
  claimActionStatus: string
  customFieldValues: CustomFieldValues
}

function Hits ({ hits }: { hits: SearchHit[] }) {
  return (
    <List>
      {hits && hits.map((h: SearchHit) => <Hit key={h.objectID} hit={h} />)}
    </List>
  )
}


const CustomHits = connectHits(Hits)

function Hit (
  { hit, ...props }: {
    hit: SearchHit
    [key: string]: any
  },
) {
  const [casinoOpen, setCasinoOpen] = useState(false)
  const [ticketData, setTicketData] = useState<TicketSnapshot | null>(null)

  const classes = useStyles()
  const hitId = hit.id ?? hit.objectID
  
  let claimLink = "claims"
  let styleAttr = {}
  let statusStyle = {}
  let status = ""
  let claimType = ""
  let providerArray:CustomFieldValues[] = []
  let output = []
  let ocStage = ""
  
  useEffect(() => {
    if (hitId) {
      return db
        .collectionGroup("tickets")
        .where("claimId", "==", hitId)
        .onSnapshot(snap => {
          if (!snap.empty) {
            setTicketData(snap.docs[0] as TicketSnapshot)
          }
          else {
            setTicketData(null)
          }
        })
    }
  }, [hit.id])

  if (hit.type === "online-casino") {
    if ( typeof hit.customFieldValues === "object") {
      if (hit.customFieldValues?.providers?.value) {
        providerArray = Object.entries(hit.customFieldValues?.providers?.value)
      }
      output = providerArray.map((values) => typeof values === "object"
        ? values[1].name : "")
    }
  }

  switch (hit.claimActionStatus) {
    case "active":
      statusStyle = {backgroundColor: "green",
        textAlign: "center",
        borderRadius: "4px"}
      status = "Active"
      break
    case "dead":
      statusStyle = {backgroundColor: "#D22B2B",
        textAlign: "center",
        borderRadius: "4px"}
      status = "Dead"
      break
    case "onHold":
      statusStyle = {backgroundColor: "#660066",
        textAlign: "center",
        borderRadius: "4px"}
      status = "On Hold"
      break
    case "finished":
      statusStyle = {backgroundColor: "#0047AB",
        textAlign: "center",
        borderRadius: "4px"}
      status = "Finished"
      break
    default:
      statusStyle = {backgroundColor: "gray",
        textAlign: "center",
        borderRadius: "4px"}
      status = "Status not set"
  }

  switch (hit.type) {
    case "online-casino":
      claimLink = "online-casino-claims"
      claimType = "Online Casino"
      break
    case "bank-fee-recovery":
      claimType = "Bank Fee Recovery"
      break
    case "DIESEL_SCANDAL":
      claimType = "Diesel"
      break
    case "diesel":
      claimType = "Diesel"
      break
    case "rejects":
      claimType = "Rejects"
      break
    default:
      claimType = hit.type
      break
  }

  if (hit.type === "online-casino") {
    claimLink = "online-casino-claims"
    claimType = "Online Casino"
  }

  if (typeof ticketData === "object") {
    ocStage = ticketData?.data()?.assignedGroup
  }

  if (hit.type !== "DIESEL_SCANDAL" && hit.type !== "diesel") {
    styleAttr = {height: "auto"}
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Card style={styleAttr} className={classes.card}>
          <ListItem>
            <Link to={`/${claimLink}/${hitId}/show`}>
              {/* @ts-expect-error */}
              <EditIcon
                className={classes.editIcon}
                button
                {...props}
              />
            </Link>
            <CardHeader
              className={classes.cardHeader}
              title={hitId} alignItems='flex-start'
            />
          </ListItem>
          <Divider />
          <ListItem>
            {
              (hit.type === "online-casino") 
                ? <CasinoIcon className={classes.listIcons} /> 
                : (hit.type === "bank-fee-recovery") 
                  ? <AccountBalanceWalletIcon className={classes.listIcons} />
                  : <ClaimIcon className={classes.listIcons}/>
            }
            <ListItemText
              className={classes.body}
              primary={claimType}
            />
            <ListItemText
              style={statusStyle}
              className={classes.body}
              primary={status}
              secondary={ocStage ? ocStage : ""}
            />
          </ListItem>
          <Divider />
          {hit.type === "online-casino" &&
          <>
            <ListItem 
              onClick={() => setCasinoOpen(!casinoOpen)}
              style={{cursor: "pointer"}}>
              <ListIcon className={classes.listIcons}/>
              <ListItemText
                className={classes.body}
                primary="List of Casinos"/>
              {casinoOpen ? <ExpandLess/> : <ExpandMore/>}
            </ListItem >
            <Collapse in={casinoOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {output.map((name) =>
                  <ListItem style={{paddingLeft: "3rem"}}>
                    <ListItemText
                      className={classes.body}
                      primary={name}
                    />
                  </ListItem>,
                )
                }
              </List>
            </Collapse>
          </>
          }
          <Divider />
          <ListItem>
            <PermIdentityIcon className={classes.listIcons} />
            <ListItemText
              className={classes.body}
              primary={hit.purchaseContractFirstName}
            />
            <ListItemText
              className={classes.body}
              primary={hit.purchaseContractLastName}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <BusinessIcon className={classes.listIcons} />
            <ListItemText
              className={classes.body}
              primary={hit.companyName}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <EmailIcon className={classes.listIcons} />
            <ListItemText
              className={classes.body}
              primary={hit.userEmail}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <PhoneIcon className={classes.listIcons} />
            <ListItemText
              className={classes.body}
              primary={hit.phoneNumber}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <LocationOnIcon className={classes.listIcons} />
            <ListItemText
              className={classes.body}
              primary={hit.address}
            />
            <ListItemText
              className={classes.body}
              primary={hit.city}
            />
            <ListItemText
              className={classes.body}
              primary={hit.postcode}
            />
          </ListItem>
          {
            hit.type === ("DIESEL_SCANDAL" || "diesel") &&
              <>
                <Divider />
                <ListItem>
                  <DirectionsCarIcon className={classes.listIcons} />
                  <ListItemText
                    className={classes.body}
                    primary={hit.carBrand}
                  />
                  <ListItemText
                    className={classes.body}
                    primary={hit.carModel}
                  />
                  <ListItemText
                    className={classes.body}
                    primary={hit.carYear}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <FingerprintIcon className={classes.listIcons} />
                  <ListItemText
                    className={classes.body}
                    primary={hit.vinNumber}
                  />
                </ListItem>
              </>
          }
        </Card>
      </Grid>
    </div>

  )
}


function Results (
  { searchState, children }: {
    searchState: { query: string }
    children: any
  },
) {
  if (!searchState || !searchState.query) {
    return null
  }
  return children
}


const CustomResults = connectStateResults(Results)


export default function Search (props: Record<string, unknown>) {
  const classes = useStyles()
  return (
    <Container>
      <InstantSearch
        searchClient={customSearchClient}
        indexName={`${process.env.REACT_APP_ALGOLIA_ENV_PREFIX}_claims`}
      >
        <Panel className={classes.searchPanel} header='SEARCH CLAIMS' />
        <SearchBox autoFocus />
        <CustomResults>
          {/* @ts-expect-error */}
          <CustomHits hitComponent={Hit} />
        </CustomResults>
      </InstantSearch>
    </Container>
  )
}
